<template>
    <div class="comp-news">
        <ul :class="'comp-news--' + type" v-if="data && data.length">
            <li v-for="(item, i) in data" :key="i" @click="$router.push('itemListInfo?id=' + item.Id + '&apiPath=' + apiPath)">
                <template v-if="type === 'pic'">
                    <router-link :to="item.link ? item.link : ''" :target="target">
                        <div class="img">
                            <img :src="item.Illustration" alt="item.Headline" class="image" />
                        </div>
                        <span class="title text-over-2">{{ item.Headline }}</span>
                        <!-- <h3>{{ item.Headline }}</h3> -->
                        <p>{{ item.ReleaseTime }}</p>
                    </router-link>
                </template>
                <template v-else>
                    <router-link :to="item.link ? item.link : ''" :target="target">
                        <div class="date" v-if="type === 'calendar'">
                            <strong>{{ item.ReleaseTime | dateTimeFilter("dd") }}</strong>
                            <span>{{ item.ReleaseTime | dateTimeFilter("yyyy-MM") }}</span>
                        </div>
                        <div class="title11">{{ item.Name }}</div>
                        <div class="text summary" style="margin-top: 2px;" v-html="item.Description"></div>
                    </router-link>
                </template>
            </li>
        </ul>
        <div v-else class="g-nodata"></div>
    </div>
</template>

<script>
    import { dateTimeFilter } from "@/filters/index";
    export default {
        name: "ic-news",
        props: {
            data: {
                type: Array
            },
            type: {
                type: String,
                default: "text"
            },
            apiPath: {
                type: String,
                default: "enterpriseProject"
            },
            target: {
                type: String,
                default: "_self"
            }
        },
        filters: {
            dateTimeFilter
        }
    };
</script>

<style lang="scss" scoped>
    .comp-news {
        width: 346px !important;
        display: flex;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 18px;
        }

        .text {
            margin-top: 8px !important;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
        }

        .summary {
            max-height: 30px;
            overflow: hidden;
        }

        >ul {
            width: 100%;
            margin: 0px;
            padding: 0px;
            list-style-type: disc;
            margin-block-start: 0px;
            margin-block-end: 0px;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
        }

        li {
            padding: 16px 0;
            padding: 0px 0 0px 77px;
            height: 68px;
            list-style: none;

            a {
                text-decoration: none;

                &:hover {
                    .title {
                        color: #3d5afe;
                    }

                    .text {
                        color: #333333;
                    }
                }
            }

            h3 {
                color: rgba(51, 51, 51, 1);
                padding-top: 2px;
                line-height: 14px;
                font-weight: 500;
                font-size: 14px;
                padding-bottom: 6px;
                margin: 0;
                // @include ellipsis();
            }

            p {
                font-size: 12px;
                color: #999;
                margin: 0;
                margin-top: 8px;
                line-height: 12px;
                // @include ellipsis();
            }

            h3,
            p {
                max-width: 100%;
            }
        }

        &--text {
            li {
                padding-left: 10px;
                position: relative;

                a {
                    &::before {
                        background-color: #999;
                        width: 4px;
                        height: 4px;
                        display: inline-block;
                        content: " ";
                        position: absolute;
                        left: 0;
                        top: 23px;
                        border-radius: 50%;
                    }

                    &:hover {
                        &::before {
                            background-color: #3d5afe;
                        }
                    }
                }
            }
        }

        &--calendar {
            li {
                padding: 0px 0 0px 77px;
                height: 68px;
                position: relative;

                a {
                    h3 {
                        padding-top: 0px;
                        padding-bottom: 4px;
                    }

                    p {
                        margin: 0;
                        margin-top: 8px;
                        line-height: 16px;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    .date {
                        width: 67px;
                        height: 60px;
                        background: #f4f4f4;
                        border-radius: 4px;
                        display: inline-block;
                        content: " ";
                        position: absolute;
                        left: 0;
                        text-align: center;

                        strong {
                            font-size: 30px;
                            color: #3d5afe;
                            line-height: 32px;
                            display: block;
                            font-weight: 500;
                            margin-top: 8px;
                            margin-bottom: 2px;
                        }

                        span {
                            font-size: 10px;
                            color: rgba(153, 153, 153, 1);
                            line-height: 10px;
                            display: block;
                        }
                    }

                    &:hover {
                        .date {
                            background-color: #3d5afe;
                            color: #fff;

                            strong,
                            span {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        &--pic {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 376px;
                padding: 0 24px 30px 0;
                border-bottom: none;

                .image {
                    width: 376px;
                    height: 266px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                }

                .title {
                    font-size: 20px;
                    line-height: 28px;
                    margin: 10px 0 7px 0;
                }

                p {
                    font-size: 16px;
                    color: rgba(153, 153, 153, 1);
                    line-height: 18px;
                }

                &:nth-child(3n) {
                    padding-right: 0;
                }
            }
        }
    }
</style>