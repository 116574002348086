<template>
  <div class="cooperation">
    <div class="banner">
      <div class="info" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
        <div class="flex">
          <img style="width: 80px; height: 80px;border-radius:4px;" v-real-img="firmInfo.LogoFullPath" src="@/assets/images/default_logo.png" alt="" />
          <div class="right">
            <div class="headline">{{ firmInfo.EnterpriseName }}</div>
            <div class="intro">
              <!-- <span>{{ firmInfo.NatureName }}</span> -->
              <span class="split"></span>
              <span>{{ firmInfo.NatureName }}
                |
                {{firmInfo.IndustryName}}
                |
                {{ firmInfo.ScaleName }}</span>
              <!-- <span class="split">|</span>
              <span>50人以上</span> -->
            </div>
          </div>
        </div>
        <!-- <div class="relation" @click="showContactus = true">
          <span class="relationBox"><img class="img" :src="relationImg" alt="" /> 联系企业</span>
        </div> -->
        <el-dialog title="校企合作联系方式" :visible.sync="showContactus" width="30%">
          <div class="contactus-list">
            <div class="contactus-item">
              <div class="contactus-icon-wrap">
                <i class="icon-style iconfont iconlianxidianhua"></i>
              </div>
              <span class="contactus-text">{{`${firmInfo.CooperationPhone || " "}${firmInfo.CooperationMan ||" "}`}}</span>
            </div>
            <div class="contactus-item">
              <div class="contactus-icon-wrap">
                <i class="icon-style iconfont iconwechat"></i>
              </div>
              <span class="contactus-text">{{firmInfo.CooperationWechat || " "}}</span>
            </div>
            <div class="contactus-item">
              <div class="contactus-icon-wrap">
                <i class="icon-style iconfont iconlianxidizhi"></i>
              </div>
              <span class="contactus-text">{{ firmInfo.Address }}</span>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="g-wrap">
      <div class="g-tabs firmTabs">
        <div class="firmTabs-sum" v-for="item in tabList" :key="item.id" @click="activeComponent = item.id" :class="{ active: item.id == activeComponent }">
          {{ item.text }}
        </div>
      </div>
    </div>
    <component :is="activeComponent" :school-info="schoolInfo" :firmInfo="firmInfo"></component>
  </div>
</template>

<script>
import info from "./components/School/Info";
import enrollment from "./components/School/Enrollment";
import partner from "./components/School/Partner";
import { getInfo } from "@/api/enterprise/enterpriseInfo";
export default {
  components: {
    info,
    enrollment,
    partner
  },
  data() {
    return {
      relationImg: require("@/assets/images/enterprise/icon_relation.png"),
      loading: false,
      id: this.$route.query.id,
      tabList: [
        {
          id: "info",
          component: info,
          text: "企业信息"
        },
        {
          id: "enrollment",
          component: enrollment,
          text: "岗位信息"
        },
        {
          id: "partner",
          component: partner,
          text: "培训课程"
        }
      ],
      firmInfo: {},
      activeComponent: "info",
      activeTab: "show",
      schoolInfo: {},
      showContactus: false
    };
  },
  watch: {
    // $route() {
    //   this.init();
    // }
  },
  methods: {
    async getInfoList() {
      this.loading = true;
      try {
        const { Result } = await getInfo(this.id);
        this.firmInfo = Result;
        console.log(this.firmInfo);
        this.loading = false;
      } catch (error) {
        this.$message.error("当前状态异常,请重新选择企业");
        this.$router.push("/cooperativeFirm");
      }
    },
    init() {
      // const path = this.$route.path.split("/");
      // const id = path[path.length - 1];
      // const matched = this.tabList.filter(item => item.id === id);
      // if (matched && matched.length) {
      //   this.activeTab = matched[0].id;
      //   this.activeComponent = matched[0].component;
      // }
      // // getInfo(this.$route.query.id).then(res => {
      // //   this.schoolInfo = res.Result;
      // // });
    }
  },
  created() {
    this.getInfoList();
    // this.init();
  }
};
</script>

<style lang="scss" scoped>
.cooperation {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  .banner {
    background: url('~@/assets/images/banner/school.png') no-repeat center top;
    background-size: 100% 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    .info {
      width: 1100px;
      height: calc(100% - 48px);
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: space-between;
      .right {
        margin-left: 16px;
        .headline {
          margin-top: 8px;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 33px;
        }
        .intro {
          margin-top: 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          .split {
            line-height: 1;
            margin: 0 4px;
          }
        }
      }
      .btn-contactus {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #ffffff;
        width: 116px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #ffffff;
        &:hover {
          opacity: 0.8;
        }
      }
      .relation {
        width: 116px;
        height: 40px;
        user-select: none;
        cursor: pointer;
        border-radius: 20px;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        .relationBox {
          box-sizing: border-box;
          .img {
            width: 14px;
            height: 14px;
            line-height: 40px;
            text-align: center;
          }
        }
      }
    }
  }
  .g-wrap {
    position: relative;
    margin: 0 auto;
    .firmTabs {
      border-radius: 8px 8px 0px 0px;
      overflow: hidden;
      .firmTabs-sum {
        flex: 1;
        cursor: pointer;
      }
      .active {
        background-color: #fff;
      }
    }
  }
  .contactus-list {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 20px 20px 20px;

    .contactus-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .contactus-icon-wrap {
        width: 36px;
        height: 36px;
        background: #eeeeee;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        .icon-style {
          color: #333333;
          font-size: 16px;
        }
      }
      .contactus-text {
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
</style>
