import request from '@/utils/request'
const controller = '/RecruitNews'

export function getPageList(query) {
  return request({
    url: `${controller}/GetPageList`,
    method: 'get',
    params: query
  })
}

export function getAllList() {
  return request({
    url: `${controller}/GetAllList`,
    method: 'get'
  })
}
export function getInfo(id) {
  return request({
    url: `${controller}/GetInfo`,
    method: 'get',
    params: {
      id
    }
  })
}

export function getCurrentAndAdjacent(id) {
  return request({
    url: `${controller}/GetCurrentAndAdjacent`,
    method: 'get',
    params: {
      id
    }
  })
}

export function createEntity(data) {
  return request({
    url: `${controller}/Insert`,
    method: 'post',
    data
  })
}

export function updateEntity(data) {
  return request({
    url: `${controller}/Update`,
    method: 'post',
    data
  })
}

export function deleteEntity(id) {
  return request({
    url: `${controller}/Delete`,
    method: 'post',
    params: {
      id
    }
  })
}

export function getNewsTypeOptions() {
  return request({
    url: `${controller}/GetNewsTypeOptions`,
    method: 'get'
  })
}
