import { BannerTypeEnum } from "@/utils/constant";
import request from "@/utils/request";

const getNewsPageList = query =>
  request({
    url: `/News/GetPageList`,
    method: "get",
    params: query
  });

const getBannerPageList = query =>
  request({
    url: `/Banner/GetPageList`,
    method: "get",
    params: {
      ...query,
      Type: BannerTypeEnum.PcHome
    }
  });

const getRecruitNews = query =>
  request({
    url: `/RecruitNews/GetPageLis`,
    method: "get",
    params: query
  });

const addAccessLog = data =>
  request({
    url: "/AccessLog/Insert",
    method: "post",
    data
  });

const getHomeProjectPageList = params =>
  request({
    url: `/EnterpriseProject/GetPageList`,
    method: "get",
    params
  });

const getCourseSeriesPageList = params =>
  request({
    url: `/CourseSeries/GetPageList`,
    method: "get",
    params
  });

const getEnterpriseInfoPageList = params =>
  request({
    url: `/EnterpriseInfo/GetPageList`,
    method: "get",
    params
  });

export {
  getNewsPageList,
  getBannerPageList,
  getRecruitNews,
  getHomeProjectPageList,
  getCourseSeriesPageList,
  getEnterpriseInfoPageList,
  addAccessLog
};
