import request from "@/utils/request";
const controller = "/RecruitJob";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });

const getJobPageList = params =>
  request({
    url: `${controller}/getJobPageList`,
    method: "get",
    params
  });

const refresh = id =>
  request({
    url: `${controller}/Refresh`,
    method: "post",
    params: { id }
  });

const toggleStatus = id =>
  request({
    url: `${controller}/ToggleStatus`,
    method: "post",
    params: { id }
  });

const deleteJob = id =>
  request({
    url: `${controller}/Delete`,
    method: "post",
    params: { id }
  });

const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: { id }
  });

const createJob = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
const editJob = data =>
  request({
    url: `${controller}/Update`,
    method: "post",
    data
  });

const getRecruitTypeList = _ =>
  request({
    url: `${controller}/GetRecruitTypeList`,
    method: "get"
  });

const getSalaryList = _ =>
  request({
    url: `${controller}/GetSalaryList`,
    method: "get"
  });

const getJobCategory = _ =>
  request({
    url: `${controller}/GetJobCategory`,
    method: "get"
  });
const getExperienceList = _ =>
  request({
    url: `${controller}/GetExperienceList`,
    method: "get"
  });
const getEducationList = _ =>
  request({
    url: `${controller}/GetEducationList`,
    method: "get"
  });
const getJobTagList = _ =>
  request({
    url: `${controller}/GetJobTagList`,
    method: "get"
  });

const getJobList = (keyword, categoryId) =>
  request({
    url: `${controller}/GetJobList`,
    method: "get",
    params: {
      keyword,
      categoryId
    }
  });
const getMyList = () =>
  request({
    url: `${controller}/GetMyList`,
    method: "get"
  });

const interview = data =>
  request({
    url: `${controller}/Interview`,
    method: "post",
    data
  });

export {
  getPageList,
  getJobPageList,
  refresh,
  toggleStatus,
  deleteJob,
  getInfo,
  createJob,
  editJob,
  getRecruitTypeList,
  getJobCategory,
  getExperienceList,
  getEducationList,
  getJobTagList,
  getJobList,
  getSalaryList,
  getMyList,
  interview
};
