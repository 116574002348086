<template>
  <div class="main-wrap" ref="mainWrap">
    <!-- 简介 -->
    <div class="coop-wrapInfo" style="background: #fff;" v-loading="loading">
      <el-backtop target=".app-container .main-wrap"></el-backtop>
      <div class="my-firmInfo">
        <div class="info-header">
          <span class="header-icon"></span>
          <span @click="getServeList">公司简介</span>
        </div>
        <div class="info-txtBox">
          <div class="txtBox-text">
            <div class="text" v-html="firmInfo.Introduction"></div>
            <div class="btnInfo">
              <el-button type="text" @click="isIntroduction = true">查看详细+</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业导师 -->
    <div class="coop-wrap" v-loading="loading">
      <div class="g-wrap f-wrap">
        <CardHeader :title="'企业导师'">
          <template v-slot:btn><a @click="$router.push('ViewTutor?id=' + enterpriseId)">更多+</a></template>
        </CardHeader>
        <div class="column-wrap">
          <tutor-card :firmInfoId="firmInfo.Id" :cardList="StaffList" />
        </div>
      </div>
    </div>
    <!-- 项目-资源-发展 -->
    <div class="g-wrap news-wrap" v-loading="loading">
      <div class="my-listCard">
        <CardHeader :title="'合作项目'">
          <template v-slot:btn><a @click="$router.push('CooperativeItem?id=' + enterpriseId)">更多+</a></template>
        </CardHeader>
        <div class="column-wrap">
          <ListCard v-if="ServicePageList && ServicePageList.length > 0" :apiPath="'enterpriseProject'" :data="ServicePageList" type="calendar"></ListCard>
          <img v-else class="img" src="@/assets/images/nodata.png" alt="" />
        </div>
      </div>
      <div class="my-listCard">
        <CardHeader :title="'投入资源'">
          <template v-slot:btn><a @click="$router.push('investmentItem?id=' + enterpriseId)">更多+</a></template>
        </CardHeader>
        <div class="column-wrap">
          <ListCard v-if="InvestmentPageList && InvestmentPageList.length > 0" :apiPath="'enterpriseInvestment'" :data="InvestmentPageList" type="calendar"></ListCard>
          <img v-else class="img" src="@/assets/images/nodata.png" alt="" />
        </div>
      </div>
      <!-- 右边 -->
      <div class="my-listCard">
        <div class="info-header">
          <div class="left">
            <span class="header-icons"></span>
            <span>员工发展</span>
          </div>
          <div class="right"></div>
        </div>
        <div class="column-wrap">
          <ListBigCard :firmInfoId="enterpriseId" :data="jobList" type="calendar"></ListBigCard>
        </div>
      </div>
    </div>
    <!-- 产品服务 -->
    <div class="coop-wrap" style="background: #F5F5F5;" v-loading="loading">
      <div class="g-wrap f-wrap my-wrap">
        <CardHeader :title="'产品服务'">
          <template v-slot:btn><a @click="$router.push('servesIndex?id=' + enterpriseId)">更多+</a></template>
        </CardHeader>
        <div class="column-wrap">
          <ServeListCard :data="ServePageList" />
        </div>
      </div>
    </div>
    <!-- <el-tooltip popper-class="contactus-tooltip" effect="light" placement="top">
      <div slot="content" class="contactus-prop">
        <span class="prop-title">校企合作咨询</span>
        <div class="prop-qrcode">
          <img
            style="width:114px;height:114px;object-fit: cover;"
            src="https://zxj.ve-city.com/files/zxj/portal/kf_qrcode.png"
            alt
          />
        </div>
        <span class="prop-text">扫码加微信咨询</span>
        <span class="prop-text">18676720290（李老师）</span>
      </div>
      <div class="contactus-wrap">
        <i class="iconfont iconkefuzixun contactus-icon"></i>
      </div>
    </el-tooltip> -->
    <el-dialog center :title="firmInfo.EnterpriseName" :visible.sync="isIntroduction" width="50%" :before-close="IntroductionbeforeClose">
      <div class="isIntroduction-box" v-html="firmInfo.Introduction"></div>
    </el-dialog>
    <AnswerTop />
  </div>
</template>

<script>
import AnswerTop from "@/components/AnswerTop";
import ServeListCard from "./infoCard/Serve/ServeListCard.vue"; // 产品服务卡片
import CardHeader from "@/components/Common/CardHeader.vue";
import TutorCard from "./infoCard/TutorCard.vue";
import ListBigCard from "./infoCard/ListBigCard.vue";
import ListCard from "./infoCard/ListCard.vue";
// import CompanyCard from "@/components/Common/CompanyCard";
import { getNewsPageList, addAccessLog } from "@/api/home";
import { getPageList as getEnterpriseList } from "@/api/enterprise/enterpriseInfo";
// getJobPageList
import { getPageList as getJobList } from "@/api/job";
import { getPageList as getRecruitNews } from "@/api/recruitnews";

import { getPageList as getStaffList } from "@/api/enterprise/enterpriseStaff";
import { getPageList as getServicePageList } from "@/api/enterprise/productionService";
import { getPageList as getInvestmentPageList } from "@/api/enterprise/resourceInvestment";
import { getPageList as getProjectPageList } from "@/api/enterprise/enterpriseProject";

export default {
  props: {
    firmInfo: {
      type: [Object, Array],
      required: true
    }
  },
  components: {
    TutorCard,
    ListCard,
    ListBigCard,
    CardHeader,
    ServeListCard,
    AnswerTop
  },
  data() {
    return {
      isIntroduction: false, // 公司简介dialog
      loading: false,
      // 导师
      StaffList: [],
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      queryStaffData: {
        EnterpriseId: sessionStorage.getItem("enterpriseId"),
        IsTeacher: true, // 是否导师-默认是
        PageSize: 6,
        PageIndex: 1
      },
      // 合作项目
      ServicePageList: null,
      queryServiceData: {
        EnterpriseId: sessionStorage.getItem("enterpriseId"),
        PageSize: 4,
        PageIndex: 1
      },
      // 资源
      InvestmentPageList: [],
      InvestmentServeData: {
        EnterpriseId: sessionStorage.getItem("enterpriseId"),
        PageSize: 4,
        PageIndex: 1
      },
      // 服务
      ServePageList: [],
      queryServeData: {
        EnterpriseId: sessionStorage.getItem("enterpriseId"),
        PageSize: 3,
        PageIndex: 1
      },

      newsColumn: {
        name: "新闻资讯",
        enName: "News",
        link: "/information"
      },
      industryColumn: {
        name: "招生动态",
        enName: "Recruit",
        link: "/recruitment/recruit"
      },
      enterpriseColumn: {
        name: "培训课程",
        enName: "Cooperative enterprise",
        link: "/cooperation/enterprise"
      },
      schoolColumn: {
        name: "合作企业",
        enName: "Cooperative college",
        link: "/cooperation/school"
      },
      jpbColumn: {
        name: "精选岗位",
        enName: "Selected posts",
        link: "/cooperation/school"
      },
      coopList: [],
      jobList: [],
      slideNews: [],
      topNews: [],
      searchZone: "1",
      searchKeyword: "",
      searchArea: [],
      listEnterprise: [],
      listJob: [],
      listJobPageIndex: 1,
      listJobTotalCount: -1,
      topList: [
        {
          Picture:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          HeadLine: "2021年全国职业教育大会",
          link: "information/detail"
        },
        {
          Picture:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          HeadLine: "2021年全国职业教育大会",
          link: "information/detail"
        }
      ]
    };
  },
  created() {
    this.getStaffList();
    this.getServeList();
    const log = {
      BusinessType: "Home",
      BusinessId: "",
      Client: "Portal"
    };
    this.getInvestmentList();
    this.getNews();
    this.getRecruitNews();
    this.getEnterprises();
    this.getJobs();
    this.getServiceList();
    addAccessLog(log);
  },
  mounted() { },

  methods: {
    // 导师
    async getStaffList() {
      this.loading = true;
      try {
        const { Result } = await getStaffList(this.queryStaffData);
        this.StaffList = Result;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    // 合作项目
    async getServiceList() {
      this.loading = true;
      try {
        const { Result } = await getProjectPageList(this.queryServiceData);
        // let res = { ...Result, Type: "Service" };
        this.ServicePageList = Result.Data.slice(0, 4);
        // console.log(this.queryServiceData);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    // 资源
    async getInvestmentList() {
      this.loading = true;
      try {
        const { Result } = await getInvestmentPageList(
          this.InvestmentServeData
        );
        // let res = { ...Result, Type: "Investment" };
        this.InvestmentPageList = Result.Data.slice(0, 4);
        // console.log("资源", this.InvestmentPageList);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    // 服务
    async getServeList() {
      this.loading = true;
      try {
        const { Result } = await getServicePageList(this.queryServeData);
        // let res = { ...Result, Type: "Serve" };
        this.ServePageList = Result.Data;
        console.log("服务", Result);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    handleChangeArea(value) {
      // console.log(value);
    },
    getNews() {
      var query = {
        PageSize: 7,
        PageIndex: 1
      };
      getNewsPageList(query).then(res => {
        if (res.IsSuccess) {
          this.topNews = res.Result.Data.slice(0, 2);
          this.slideNews = res.Result.Data.slice(2);
        }
      });
    },
    getRecruitNews() {
      var query = {
        PageSize: 4,
        PageIndex: 1
      };
      getRecruitNews(query).then(res => {
        if (res.IsSuccess) {
          // /recruitment/recruit-detail?id=
          this.jobList = this.checkNews(res.Result.Data);
        }
      });
    },
    checkNews(list) {
      return list.map(el => {
        return {
          ...el,
          link: `/recruitment/recruit-detail?id=${el.Id}`
        };
      });
    },
    getEnterprises() {
      var query = {
        PageSize: 24,
        PageIndex: 1,
        Status: 2
      };
      getEnterpriseList(query).then(res => {
        if (res.IsSuccess) {
          this.listEnterprise = res.Result.Data;
        }
      });
    },
    getJobs() {
      var query = {
        PageSize: 6,
        PageIndex: 1
      };
      getJobList(query).then(res => {
        // getJobPageList(query).then(res => {
        if (res.IsSuccess) {
          this.listJob = res.Result.Data;
          this.listJobTotalCount = res.Result.TotalCount;
        }
      });
    },
    turnListJob() {
      if (this.listJobPageIndex < this.listJobTotalCount / 6) {
        this.listJobPageIndex++;
      } else {
        this.listJobPageIndex = 1;
      }
      var query = {
        PageSize: 6,
        PageIndex: this.listJobPageIndex
      };
      getJobList(query).then(res => {
        if (res.IsSuccess) {
          this.listJob = res.Result.Data;
          this.listJobTotalCount = res.Result.TotalCount;
        }
      });
    },
    goAirSessions() {
      window.open("https://zxj.ve-city.com/sessions/", "_blank");
    },
    goCourse() {
      this.$message.info("建设中...");
    },
    IntroductionbeforeClose() {
      this.isIntroduction = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.search-wrap {
  display: flex;
  justify-content: center;
  .search-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  margin-bottom: 20px;
}
.enterprise-wrap {
  min-height: 200px;
  display: flex;
  background-color: #f6f6f8;
  justify-content: center;
  .enterprise-con {
    .enterprise-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .enterprise {
        display: flex;
        background-color: #ffffff;
        width: 261px;
        height: 120px;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
          box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
.job-wrap {
  min-height: 200px;
  display: flex;
  background-color: #f6f6f8;
  justify-content: center;
  .job-con {
    .job-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .job {
        display: flex;
        background-color: #ffffff;
        width: 350px;
        height: 160px;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        &:hover {
          box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
.coop-wrapInfo {
  background-color: #f6f6f9;
  display: flex;
  justify-content: center;
}
.coop-wrap {
  padding-bottom: 40px;
  background-color: #f6f6f9;
  display: flex;
  justify-content: center;
}
.column-wrap {
  // overflow: hidden;
  display: flex;
  position: relative;
  min-height: 144px;
  width: 100%;
  .img {
    width: 100%;
    height: 100%;
  }
  .card-carousel {
    width: 100%;
    .el-carousel-item {
      width: 100%;
    }
  }
}
// 培训课程
.column-wrap {
}

.card-turn-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  color: #fff;
  opacity: 0.1;
  top: 164px;
  cursor: pointer;
  &:hover {
    opacity: 0.2;
  }
}
.card-right {
  right: -60px;
}
.card-left {
  left: -60px;
}
.news-wrap {
  display: flex;
  // @include clearfix();
  margin: 0 auto;
  justify-content: space-between;
  padding-bottom: 32px;
  .my-listCard {
    width: 346px;
    .info-header {
      padding: 40px 0 15px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      .left {
        display: flex;
        align-items: center;
      }
      .right {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3d5afe;
        line-height: 22px;
      }
      .header-icons {
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 8px;
        background: #3d5afe;
        border-radius: 2px;
      }
    }
  }
  .news {
    margin-right: 16px;
    flex-grow: 1;
    .info-header {
      padding: 40px 0 15px;
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      .header-icons {
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 8px;
        background: #3d5afe;
        border-radius: 2px;
      }
    }
  }
  .comp-slide-news {
    width: 356px;
    height: 264px;
  }
  .top-news {
    margin-left: 16px;
  }
  .comp-news {
    width: 432px;
    height: 288px;
  }
  .industry {
    width: 432px;
  }
}
.f-wrap {
  flex-wrap: wrap;
}
.service-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -40px;
  z-index: 99;
}
.service-item {
  display: flex;
  // flex-direction: column;
  width: 260px;
  height: 120px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(238, 238, 238, 1);
  border-radius: 8px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.12);
    .service-title {
      color: #3d5afe;
    }
  }
  .service-title {
    margin-left: 16px;
    font-size: 20px;
    color: #333333;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    &-detail {
      height: 20px;
      margin-top: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
      &-headline {
        color: #3d5afe;
      }
    }
  }
}
.contactus-wrap {
  display: flex;
  position: fixed;
  right: 32px;
  bottom: 100px;
  width: 56px;
  height: 56px;
  border-radius: 36px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(1, 188, 119, 0.2);
  align-items: center;
  cursor: pointer;
  justify-content: center;
  .contactus-icon {
    font-size: 32px;
    color: #cccccc;
  }
  &:hover {
    background: #3d5afe;
    .contactus-icon {
      font-size: 32px;
      color: #ffffff;
    }
  }
}
.contactus-prop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 280px;
  box-sizing: border-box;
  padding: 8px;
  .prop-title {
    font-weight: 600;
    color: #3d5afe;
    font-size: 18px;
  }
  .prop-qrcode {
    width: 130px;
    height: 130px;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 6px;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prop-text {
    color: #666666;
    font-size: 14px;
    margin-bottom: 4px;
  }
}
::v-deep .el-carousel {
  width: 100%;
  .el-carousel__indicators {
    width: 100%;
    text-align: center;
  }
  .el-carousel__button {
    width: 32px;
    height: 6px;
    background: #dddddd;
    border-radius: 3px;
  }
  .is-active {
    .el-carousel__button {
      background: #3d5afe;
    }
  }
}
.comp-column-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px 0 3px 0;
  .left {
    display: flex;
    align-items: center;
    .info-header {
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      .header-icons {
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 8px;
        background: #3d5afe;
        border-radius: 2px;
      }
    }
  }
  .right {
    margin-top: 4px;
    a {
      user-select: none;
      font-size: 14px;
      color: #3d5afe;
      line-height: 17px;
      text-decoration: none;
      cursor: pointer;
      i {
        margin-right: 2px;
      }
      &:hover {
        color: #3d5afe;
      }
    }
  }
}
.my-wrap {
  width: 1100px;
  justify-content: center;
  .comp-column-header {
    width: 1100px;
    padding-bottom: 0;
  }
}

.my-firmInfo {
  width: 1100px;
  min-height: 382px;
  .info-header {
    margin: 40px 0 17px 0;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    .header-icon {
      display: inline-block;
      width: 4px;
      height: 20px;
      margin-right: 8px;
      background: #3d5afe;
      border-radius: 2px;
    }
  }
  .info-txtBox {
    display: flex;
    .txtBox-text {
      width: 100%;
      height: 100%;
      /deep/ .text {
        width: 100%;
        height: 192px;
        overflow: hidden;
        margin-right: 39px;
        border-radius: 6px;
        img {
          display: block;
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
        }
        // border: 1px solid #eeeeee;
      }
      .btnInfo {
        margin-top: 18px;
      }
    }
    .txtBox-img {
      width: 376px;
      height: 266px;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
/deep/ .isIntroduction-box {
  width: 100%;
  overflow: auto;
  min-height: 480px;
  height: 100%;
  img {
    display: block;
  }
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
  }
}
</style>

<style lang="scss">
.el-tooltip__popper.is-light .popper__arrow {
  border-top-color: #fff !important;
}
</style>
