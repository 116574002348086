<template>
    <div class="g-post">
        <div class="screen">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini">
                <el-form-item @click.native="getPageList" label="工作区域" class="mr-72">
                    <el-cascader @change="getPageList" v-model="queryData.City" :options="AreaOptions" :props="props" :show-all-levels="false"></el-cascader>
                </el-form-item>
                <el-form-item label="职位类型">
                    <el-select @change="getPageList" v-model="queryData.RecuritType" :props="{ emitPath: false }" placeholder="请选择">
                        <el-option v-for="(item, index) in RecruitTypeOptions" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="薪资待遇">
                    <el-select @change="getPageList" v-model="queryData.Salary" :props="{ emitPath: false }" placeholder="请选择">
                        <el-option v-for="(item, index) in SalaryOptions" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="list" v-loading="loading">
            <job-card v-if="RecruitJobPageList.length>0" :cardList="RecruitJobPageList" />
            <div v-else class="nodata">
                <el-image style="width: 240px; height: 180px" :src="require('@/assets/images/nodata.png')" />
                <span class="mt-18">暂无数据</span>
            </div>
            <div class="loading" v-if="RecruitJobPageList.length >= 1">
                <Pagination :total="totalCount" :limit="16" :layout="'prev, pager, next'" :autoScroll="false" @pagination="areaPagination" />
            </div>
        </div>
    </div>
</template>

<script>
    // import Recruit from "@/components/Recruit";
    import JobCard from "@/components/Common/JobCard";
    import Pagination from "@/components/Pagination";
    import { getRecruitJobPageList } from "@/api/recruitJob";
    import { mapGetters, mapState } from "vuex";
    import { RecruitTypeEnum } from "@/utils/constant";
    export default {
        components: {
            JobCard,
            Pagination
        },
        data() {
            return {
                loading: false,
                AreaOptions: [],
                SalaryOptions: [],
                RecruitTypeOptions: [{
                        label: "全职",
                        value: RecruitTypeEnum.FullTime
                    },
                    {
                        label: "兼职",
                        value: RecruitTypeEnum.PartTime
                    }
                ],
                props: {
                    value: "Code",
                    label: "Name",
                    checkStrictly: true,
                    emitPath: false
                },
                RecruitJobPageList: [],
                queryData: {
                    KeyWord: null,
                    EnterpriseId: this.$route.query.id,
                    PageSize: 10,
                    PageIndex: 1,
                    City: "", //城市Code-string
                    Industry: "", // 行业-string
                    Nature: "", // 公司性质
                    Scale: "", // 公司规模
                    Salary: "",
                    RecuritType: ""
                },
                totalCount: 0,
                formInline: {
                    user: "",
                    region: ""
                }
            };
        },
        computed: {
            ...mapGetters(["employee"]),
            ...mapState("common", {
                AreaList: state => state.Area, //
                SalaryList: state => state.Salary
                // JobCategoryList: state => state.JobCategory
            })
        },
        created() {
            this.getPageList();
            this.getCondition();
        },
        mounted() {},
        methods: {
            getCondition() {
                this.toTree(this.AreaList)[0].children.forEach(el => {
                    this.AreaOptions.push(el);
                });
                this.AreaOptions.unshift({
                    Name: "全部",
                    Code: " "
                });
                this.SalaryOptions = this.SalaryList.map(({ Text, Value }) => ({
                    label: Text,
                    value: Value
                }));
            },
            // 转为树形结构
            toTree(data) {
                let result = [];
                if (!Array.isArray(data)) {
                    return result;
                }
                data.forEach(item => {
                    delete item.children;
                });
                let map = {};
                data.forEach(item => {
                    map[item.Code] = item;
                });
                data.forEach(item => {
                    let parent = map[item.ParentCode];
                    if (parent) {
                        (parent.children || (parent.children = [])).push(item);
                    } else {
                        result.push(item);
                    }
                });
                return result;
            },

            async getPageList() {
                this.loading = true;
                try {
                    const { Result } = await getRecruitJobPageList(this.queryData);
                    this.RecruitJobPageList = Result.Data;
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                }
            },
            areaPagination(val) {
                this.queryData.PageIndex = val.page;
                this.getPageList();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .g-post {
        width: 1100px;
        box-sizing: border-box;
        margin: 0 auto;

        .screen {
            height: 30px;
            margin: 24px 0 6px;

            /deep/ .el-form-item {
                margin-right: 72px;
            }
        }

        .loading {
            width: 100%;
            height: 32px;
            margin-bottom: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .list {
        width: 100%;
        min-height: 30vh;
    }

    .nodata {
        flex: 1;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-sizing: border-box;

        span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
        }
    }
</style>