<template>
  <div class="job-card-list">
    <div
      class="card-item cursor TutorCard-Box"
      v-for="(item, index) in cardList.Data"
      :key="index"
      @click="handleClick(item)"
    >
      <div class="TutorCard-left">
        <img
          class="left-img"
          v-real-img="item.Avatar"
          src="@/assets/images/student/default2.png"
          alt=""
        />
      </div>
      <div class="TutorCard-right">
        <div class="right-name">{{ item.Name }}</div>
        <div class="right-position">
          {{ item.Title ? item.Title : " " }}
          <span v-if="item.Title && item.Position"> | </span>
          {{ item.Position ? item.Position : " " }}
        </div>
        <div class="right-position">
          {{ item.Speciality ? item.Speciality : " " }}
        </div>
      </div>
    </div>
    <div class="noDataList" v-if="!cardList.Data || cardList.Data.length <= 0">
      暂无数据~
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorCard",
  props: {
    cardList: {
      type: [Array, Object],
      required: true
    }
  },
  data() {
    return {
      noDataImg: require("@/assets/images/nodata.png"),
      noImg: require("@/assets/images/student/default2.png")
    };
  },
  methods: {
    handleClick(item) {
      this.$router.push("TutorDetail?id=" + item.Id);
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.job-card-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  width: 100%;
  .TutorCard-Box:hover {
    transform: translateY(-4px);
    box-shadow: 0px 0px 28px 0px rgba(51, 51, 51, 0.16);
  }

  .TutorCard-Box {
    box-sizing: border-box;
    width: 356px;
    height: 144px;
    background: #ffffff;
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 28px;
    display: flex;
    .img {
      width: 100%;
      height: 100%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(n + 3) {
      margin-bottom: 0;
    }
    .TutorCard-left {
      width: 144px;
      height: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      .left-img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .TutorCard-right {
      padding: 24px 0;
      .right-name {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
      }
      .right-position {
        margin-top: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
    }
  }
  .noDataList {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3d5afe;
  }
}
</style>
