<template>
  <div class="ListBigCard" v-loading="loading">
    <div
      class="ListBigCard-box"
      @click="$router.push('personnelTraining?id=' + firmInfoId)"
    >
      <div class="imgBox">
        <img class="img" :src="iconImg[0]" alt="" />
      </div>
      <div class="center">
        <div class="name">人才培养方案</div>
        <div class="text">共{{ TotalCount_personne }}篇人才培养方案</div>
      </div>
      <div class="right"><i class="el-icon-arrow-right"></i></div>
    </div>
    <div
      class="ListBigCard-box"
      @click="$router.push('developmentPath?id=' + firmInfoId)"
    >
      <div class="imgBox">
        <img class="img" :src="iconImg[1]" alt="" />
      </div>
      <div class="center">
        <div class="name">员工发展路径</div>
        <div class="text">共{{ TotalCount_development }}个岗位员工发展路径</div>
      </div>
      <div class="right">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageList as getPersonnePageList } from "@/api/enterprise/enterprisePersonnelTraining";
import { getPageList as getDevelopmentPageList } from "@/api/enterprise/enterpriseDevelopmentPath";
export default {
  name: "ListBigCard",
  props: {
    firmInfoId: {
      type: String,
      required: true
    }
  },
  components: {},
  data() {
    return {
      TotalCount_personne: 0,
      TotalCount_development: 0,
      loading: false,
      iconImg: {
        0: require("@/assets/images/enterprise/icon_personne.png"),
        1: require("@/assets/images/enterprise/icon_developmentPath.png")
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    async getPersonnePageList() {
      this.loading = true;
      try {
        const { Result } = await getPersonnePageList({
          EnterpriseId: this.firmInfoId
        });
        this.TotalCount_personne = Result.TotalCount;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getDevelopmentPageList() {
      this.loading = true;
      try {
        const { Result } = await getDevelopmentPageList({
          EnterpriseId: this.firmInfoId
        });
        this.TotalCount_development = Result.TotalCount;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    }
  },
  created() {
    this.getPersonnePageList();
    this.getDevelopmentPageList();
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.ListBigCard-box:nth-child(1) {
  margin-bottom: 16px;
}
.ListBigCard-box:hover {
  box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.24);
  cursor: pointer;
}
.ListBigCard-box {
  box-sizing: border-box;
  padding-left: 20px;
  width: 346px;
  height: 116px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.12);
  &:hover {
    .el-icon-arrow-right {
      margin-left: 10px;
      font-size: 36px;
      color: #3d5afe;
    }
  }
  .imgBox {
    flex: 1;

    // margin-right: 12px;
    .img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
  }
  .center {
    flex: 2;
    .name {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
    }
    .text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-top: 8px;
    }
  }
  .right {
    flex: 1;
    color: #cccccc;
    font-size: 18px;
    font-weight: 600;
    .el-icon-arrow-right {
      transition: all 0.15s;
    }
  }
}
</style>
