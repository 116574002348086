<template>
    <div class="g-wrap info-list" v-loading="loading">
        <div v-if="list.length>0">
            <div v-for="(item, i) in list" class="info-item" :key="i" @click="toDetail(item.Id)">
                <div class="item-left">
                    <img class="item-img" v-real-img="item.CoverImageFullPath" src="@/assets/images/nodata.png" alt="" />
                    <!-- <span class="item-tag">{{ item.ContentTypeText }}</span> -->
                </div>
                <div class="item-message">
                    <div>
                        <span class="message-title">{{ item.Name }}</span>
                        <!-- <p class="message-summary text-line-2">
            {{ "内容" }}
          </p> -->

                        <div class="courseInfo">
                            <span>{{ item.SignupCount }}人学过 | 共{{ item.LessonCount }}节
                                <!-- | 时长：2:00:00 -->
                            </span>
                        </div>
                    </div>
                    <div class="message-time">
                        <div class="courseType" style="display: flex;align-items: flex-end;">
                            <span class="mr-8" v-if="item.OwnerName">{{ item.OwnerName }}</span>
                        </div>
                        <!-- <span class="mb-10">
            <div class="bot-money" v-if="item.Price">￥{{ item.Price }}</div>
            <div class="bot-money" v-else>
              免费
            </div>
          </span> -->
                        <el-button type="primary" size="mini">立即查看</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="nodata">
            <el-image style="width: 240px; height: 180px" :src="require('@/assets/images/nodata.png')" />
            <span class="mt-18">暂无数据</span>
        </div>
        <div class="loading" v-if="list && list.length >= 1">
            <Pagination :total="totalCount" :limit="10" :layout="'prev, pager, next'" @pagination="areaPagination" />
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination";
    import { getPageList } from "@/api/courseseries";
    export default {
        components: {
            Pagination
        },
        data() {
            return {
                loading: false,
                list: [],
                totalCount: 0,
                queryData: {
                    KeyWord: null,
                    OwnerId: this.$route.query.id,
                    EnterpriseId: this.$route.query.id,
                    PageSize: 10,
                    PageIndex: 1
                }
            };
        },
        methods: {
            async getPageList() {
                this.loading = true;
                try {
                    const { Result } = await getPageList(this.queryData);
                    this.list = Result.Data;
                    this.totalCount = Result.TotalCount;
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                }
            },
            toDetail(id) {
                this.$router.push("/course/detail?id=" + id);
            },
            areaPagination(val) {
                this.queryData.PageIndex = val.page;
                this.getPageList();
            }
        },
        created() {
            this.getPageList();
        }
    };
</script>

<style scoped lang="scss">
    .info-list {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        margin: 24px auto 40px !important;

        .info-item {
            transition: all 0.1s;
            box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.12);
            cursor: pointer;
            display: flex;
            box-sizing: border-box;
            padding: 20px;
            height: 186px;
            margin-bottom: 16px;
            border-radius: 8px;

            // box-shadow: 0px 1px 0px 0px #eeeeee;
            &:hover {
                transform: scale(1.015);
                box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.26),
                    0px -1px 0px 0px #eeeeee;
                border-radius: 8px;
            }

            .item-message {
                display: flex;
                flex: 1;
                flex-direction: column;
                margin-left: 20px;
                justify-content: space-between;

                .message-title {
                    display: inline-flex;
                    text-align: left;
                    font-size: 20px;
                    color: #333333;
                    font-weight: 600;
                    padding-top: 0px;
                    margin: 8px 0;
                }

                .message-summary {
                    text-align: left;
                    font-size: 14px;
                    color: #666666;
                }

                .message-time {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    color: #999999;

                    .mb-10 {
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #10dcaf;
                        line-height: 32px;
                    }

                    /deep/ .el-button {
                        margin-top: 10px;
                        width: 88px;
                        height: 32px;
                        background: #3d5afe;
                        border-radius: 4px;
                    }
                }
            }

            .courseType {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 22px;

                .mr-8 {
                    padding: 3px 8px;
                    background: #f0f2f4;
                    border-radius: 4px;
                }
            }

            .courseInfo {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 22px;
                margin: 8px 0;
            }

            &:hover {
                .message-title {
                    color: #3d5afe;
                }

                .message-summary {
                    color: #333333;
                }
            }

            .item-left {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .item-img {
                height: 160px;
                width: 280px;
            }

            .item-tag {
                color: #ffffff;
                font-size: 14px;
                background-image: url('https://zxj.ve-city.com/files/zxj/portal/bg_tag.png');
                position: relative;
                margin-top: -146px;
                margin-left: -6px;
                background-size: 100% 100%;
                height: 34px;
                width: 76px;
                text-align: center;
                box-sizing: border-box;
                padding-top: 4px;
            }
        }
    }

    .loading {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .info-list {
        // width: 100%;
        min-height: 30vh;
    }

    .nodata {
        flex: 1;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        box-sizing: border-box;

        span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
        }
    }
</style>